<template>
  <div class="job-status">
    {{ statusesById[value] }}
    <div v-for="(status, idx) in compoundStateItems(item)" :key="idx">
      <b-badge
        variant="warning"
        class="text-wrap"
        v-b-tooltip.hover
        :title="status.details"
      >
        {{ status.name }}
      </b-badge>
    </div>
  </div>
</template>

<script>
import { mapDetails } from '@gid/vue-common/components/activity/StateActivity.vue';

export default {
  name: 'JobStatusDetails',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: '',
    },
    statuses: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    statusesById() {
      return this.statuses.reduce((statuses, status) => {
        statuses[status.value] = status.text;
        return statuses;
      }, {});
    },
  },
  methods: {
    compoundStateItems(job_view) {
      return mapDetails.call(
        this,
        job_view.job.states?.list?.[0]?.startsWith(job_view.job.status) &&
          job_view.job.states?.list?.[0].includes('.')
          ? job_view.job.states?.list
          : [],
        job_view.job.states?.details,
      );
    },
  },
};
</script>
