<template>
  <div class="approval-jobs-table">
    <b-table
      :show-empty="!loading"
      striped
      stacked="md"
      ref="wrapper"
      :items="items"
      class="gid-table-infinite"
      :fields="tableColumnFields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @sort-changed="sortChanged"
      no-local-sorting
    >
      <template #cell(_brand_ids)="{ item }">
        <JobOrderInfo
          :item="item"
          :job-ratings="ratings"
          @refreshJob="refreshJob"
        />
      </template>
      <template #cell(project.name)="{ item }">
        <JobProjectName :item="item" />
      </template>
      <template #cell(customer._fullname)="{ item }">
        <JobCustomerDetails :item="item" />
      </template>
      <template #cell(job.status)="{ value, item }">
        <JobStatusDetails :value="value" :item="item" :statuses="statuses" />
      </template>
      <template #cell(job._price)="{ item }">
        <price-details :item="item" :cancellations="cancellations" />
      </template>
      <template #cell(project_manager)="{ item }">
        <JobProjectManager :item="item" />
      </template>
      <template #cell(days_since_last_status_change)="{ item }">
        <DaysSinceLastChange :days="item.days_since_last_status_change" />
      </template>
      <template #cell(car_delivery)="{ item }">
        <JobCarDeliveryInfo :item="item" />
      </template>
      <template #cell(job.appointment_start_timestamp)="{ item }">
        <JobAppointmentDetails :item="item" />
      </template>
      <template #row-details="{ item }">
        <job-details :item="item" @refresh="refreshJob(item.job.sfid)" />
      </template>
      <template #cell(_actions)="{ item, toggleDetails }">
        <JobsTableActions
          :item="item"
          @toggle="toggleDetails"
          @showLogs="$refs.logView.showLog(item.job.sfid)"
          @refreshJob="refreshJob"
        />
      </template>
      <template #custom-foot="{ columns }" v-if="items.length && loading">
        <b-tr v-for="row in perPage" :key="row">
          <b-td v-for="col in columns" :key="col">
            <b-skeleton :width="`${Math.round(Math.random() * 50) + 50}%`" />
          </b-td>
        </b-tr>
      </template>
    </b-table>
    <log-list ref="logView" :statuses="statuses" :context="'brand'" />
  </div>
</template>

<script>
import DaysSinceLastChange from '@gid/vue-common/components/DaysSinceLastChange';
import LogList from '@gid/vue-common/components/LogList';
import InfiniteScrollMixin from '@gid/vue-common/components/mixins/InfiniteScrollMixin';
import JobsTableActions from '@/components/Jobs/tables/JobsQuoteApproval/tableColumns/JobsTableActions';
import JobCustomerDetails from '@/components/Jobs/tables/JobsQuoteApproval/tableColumns/JobCustomerName';
import JobCarDeliveryInfo from '@/components/Jobs/tables/JobsQuoteApproval/tableColumns/JobCarDeliveryInfo';
import JobProjectManager from '@/components/Jobs/tables/JobsQuoteApproval/tableColumns/JobProjectManager';
import JobStatusDetails from '@/components/Jobs/tables/JobsQuoteApproval/tableColumns/JobStatusDetails';
import JobProjectName from '@/components/Jobs/tables/JobsQuoteApproval/tableColumns/JobProjectName';
import JobAppointmentDetails from '@/components/Jobs/tables/JobsQuoteApproval/tableColumns/JobAppointmentDetails';
import JobOrderInfo from '@/components/Jobs/tables/JobsQuoteApproval/tableColumns/JobOrderInfo';
import JobDetails from '@/views/JobDetails';
import PriceDetails from '@/views/PriceDetails';
import TableLoaderSkeleton from '@/components/Jobs/tables/JobsQuoteApproval/tableColumns/TableLoaderSkeleton';

export default {
  mixins: [InfiniteScrollMixin],
  components: {
    JobDetails,
    PriceDetails,
    JobsTableActions,
    JobCustomerDetails,
    JobCarDeliveryInfo,
    JobProjectManager,
    JobStatusDetails,
    JobProjectName,
    JobAppointmentDetails,
    JobOrderInfo,
    DaysSinceLastChange,
    LogList,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    statuses: {
      type: Array,
      default: () => [],
    },
    ratings: {
      type: Object,
      default: () => {},
    },
    cancellations: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sortBy: null,
      sortDesc: false,
    };
  },
  computed: {
    tableColumnFields() {
      const columns = [
        {
          label: 'flow-slas',
          key: 'days_since_last_status_change',
          sortable: true,
        },
        { label: this.$t('jobs.table.heading.id'), key: '_brand_ids' },
        {
          label: this.$t('jobs.table.heading.order-value'),
          key: 'job._price',
        },
        {
          label: this.$t('jobs.table.heading.customer'),
          key: 'customer._fullname',
        },
        {
          label: this.$t('projects.table.heading.car-delivery-date'),
          key: 'car_delivery',
          sortable: true,
          formatter: (value) => (value ? this.$moment(value).format('L') : '-'),
        },
        {
          label: this.$t('jobs.table.heading.created-date'),
          key: 'job.created_date',
          sortable: true,
          formatter: (value) => (value ? this.$moment(value).format('L') : '-'),
        },
        {
          label: this.$t('jobs.table.heading.appointment'),
          key: 'job',
          formatter: (value) =>
            value.appointment_start_timestamp ? this.getTimeRange(value) : '-',
        },
        { label: this.$t('jobs.table.heading.status'), key: 'job.status' },
        {
          label: this.$t('jobs.table.heading.documentation'),
          key: '_actions',
          tdClass: 'text-nowrap',
        },
      ];
      if (this.associatedWithProject) {
        const columnsToUpdate = [
          {
            label: this.$t('jobs.table.heading.project-id'),
            key: 'project.name',
          },
          {
            label: this.$t('jobs.table.heading.project_owner'),
            key: 'project_manager',
          },
        ];
        columns.splice(2, 0, ...columnsToUpdate);
        return columns;
      }
      columns.splice(2, 0, {
        label: this.$t('jobs.table.heading.opportunity'),
        key: 'opportunity.name',
      });
      return columns;
    },
    associatedWithProject() {
      return this.items.some(
        ({ project }) => project['id'] != null || project['name'] != null,
      );
    },
  },
  methods: {
    getTimeRange(job) {
      return (
        this.$moment(job.appointment_start_timestamp).format('L H:mm') +
        ' - ' +
        this.$moment(job.appointment_end_timestamp).format('H:mm')
      );
    },
    refreshJob(event) {
      this.$emit('refreshJob', event);
    },
    sortChanged(sortOptions) {
      const { sortBy, sortDesc } = sortOptions;
      this.$emit('sortUpdated', {
        sort_by: sortBy ?? null,
        sort_dir: sortDesc ? 'desc' : 'asc',
      });
    },
    loadData() {
      this.$emit('loadData', true);
    },
  },
};
</script>
