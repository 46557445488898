import { render, staticRenderFns } from "./JobOrderInfo.vue?vue&type=template&id=27bb7f90&scoped=true&"
import script from "./JobOrderInfo.vue?vue&type=script&lang=js&"
export * from "./JobOrderInfo.vue?vue&type=script&lang=js&"
import style0 from "./JobOrderInfo.vue?vue&type=style&index=0&id=27bb7f90&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27bb7f90",
  null
  
)

export default component.exports