<template>
  <div>
    <b-tr v-for="row in perPage" :key="row">
      <b-td v-for="col in columnsCount" :key="col">
        <b-skeleton :width="`${Math.round(Math.random() * 50) + 50}%`" />
      </b-td>
    </b-tr>
  </div>
</template>

<script>
export default {
  props: {
    columnsCount: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
};
</script>
