<template>
  <div class="customer-name">
    <div>{{ item.customer.name }}</div>
    <div>{{ getCustomerShippingAddress(item) }}</div>
    <div v-if="item.customer.company_name" class="text-muted">
      <small>{{ item.customer.company_name }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JobCustomerName',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getCustomerShippingAddress() {
      return (item) => {
        return `${item?.customer?.shipping_address?.postal_code} ${item?.customer?.shipping_address?.city}`;
      };
    },
  },
};
</script>
