<template>
  <div class="project-name">
    {{ item.opportunity.name }}
    <small class="d-block text-muted">{{ item.project.name }}</small>
  </div>
</template>

<script>
export default {
  name: 'JobProjectName',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
