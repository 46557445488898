<template>
  <div class="appointment-details">
    {{ item.job.appointment_start_timestamp ? getTimeRange(item.job) : '-' }}
    <br />
    {{ greaterDateFormatted(item.job.readiness_date, item.job.date_expected) }}
    <br />
    <strong>
      {{ latestStatusChangeFormatted }}
      {{ item.status_description.brand_name[locale] }}
    </strong>
    <div v-for="(status, idx) in compoundStateItems(item)" :key="idx">
      <b-badge variant="warning">{{ status }}</b-badge>
    </div>
    <div class="d-flex">
      <DaysSinceLastChange
        v-if="statusNotCompleted(item)"
        :days="item.days_since_last_status_change"
      />
      <DaysSinceLastChange
        v-if="item.actions_taken"
        class="ml-3"
        context="action"
        :days="item.actions_taken"
        :action_date="item.latest_action"
      />
    </div>
  </div>
</template>

<script>
import DaysSinceLastChange from '@gid/vue-common/components/DaysSinceLastChange';
import { mapDetails } from '@gid/vue-common/components/activity/StateActivity.vue';
import { JobStatusEnum } from '@gid/models';

export default {
  components: {
    DaysSinceLastChange,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    latestStatusChangeFormatted() {
      return this.item.job.last_status_change
        ? this.$t('statuschange', {
            status_change_date: this.$moment(
              this.item.job.last_status_change,
            ).format('L'),
          })
        : this.$t('statuschange', {
            status_change_date: this.$moment(this.item.job.created_date).format(
              'L',
            ),
          });
    },
    statusNotCompleted() {
      return (item) => item?.job?.status !== JobStatusEnum.COMPLETED;
    },
  },
  methods: {
    greaterDateFormatted(a, b) {
      const date = a && b ? (a > b ? a : b) : a ? a : b;
      return date
        ? this.$t('readyforinstallation', {
            installationdate: this.$moment(date).format('L'),
          })
        : '-';
    },
    getTimeRange(job) {
      return (
        this.$moment(job.appointment_start_timestamp).format('L H:mm') +
        ' - ' +
        this.$moment(job.appointment_end_timestamp).format('H:mm')
      );
    },
    compoundStateItems(job_view) {
      return mapDetails.call(
        this,
        job_view.job.states?.list?.[0]?.startsWith(job_view.job.status) &&
          job_view.job.states?.list?.[0].includes('.')
          ? job_view.job.states?.list
          : [],
        job_view.job.states?.details,
      );
    },
  },
};
</script>
