<template>
  <b-container fluid>
    <JobFilters
      :statuses="statuses"
      :project-managers="projectManagers"
      :totalRows="totalRows"
      :advanced-filter-options="advancedFilterOptions"
      :exportState="exportState"
      :exportError="exportError"
      @filtersUpdated="filtersUpdated"
      @downloadReport="downloadJobsReport"
    />
    <JobsQuoteApprovalTable
      :items="items"
      :loading="loading"
      :statuses="statuses"
      :ratings="jobRatings"
      :cancellations="cancellations"
      :per-page="perPage"
      @refreshJob="refreshJob"
      @loadData="loadData"
      @sortUpdated="sortUpdated"
    />
    <JobTableResultsWidget
      :is-loading="loading"
      :total-count="totalRows"
      :items-count="items.length"
    />
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Api from '@gid/vue-common/api';

import AdvancedFilterOptionsMixin from '../mixins/AdvancedFilterOptionsMixin';
import JobsQuoteApprovalTable from '@/components/Jobs/tables/JobsQuoteApproval/JobQuoteApprovalTable';
import JobTableResultsWidget from '@/components/Jobs/tables/JobsQuoteApproval/JobTableResultsWidget';
import JobFilters from '@/components/Jobs/filters/JobFilters';

import { JobStatusEnum } from '@gid/models';

export default {
  mixins: [AdvancedFilterOptionsMixin],
  components: {
    JobFilters,
    JobsQuoteApprovalTable,
    JobTableResultsWidget,
  },
  data() {
    return {
      items: [],
      perPage: 10,
      totalRows: null,
      loading: false,
      statuses: [],
      cancellations: {},
      projectManagers: [],
      jobRatings: {},
      filters: {
        project_manager: null,
        search: '',
        sort_by: null,
        sort_dir: 'asc',
        status: [JobStatusEnum.BRAND_PROPOSAL_APPROVAL_PENDING],
        from_date: null,
        to_date: null,
      },
      filterUseAdvancedSearch: false,
      filterAdvancedSearch: { or: [] },
      exportState: 'available',
      exportError: null,
    };
  },
  computed: {
    ...mapGetters(['locale']),
  },
  created() {
    this.loadData();
    this.loadBrandOpportunities();
    this.loadOrderStatuses();
    this.loadCancellationReasons();
    this.loadProjectsManagers();
  },
  methods: {
    filtersUpdated(filterValues) {
      this.exportState = 'available';
      const { filterUseAdvancedSearch, filterAdvancedSearch, ...rest } =
        filterValues;
      this.filterUseAdvancedSearch = filterUseAdvancedSearch;
      this.filterAdvancedSearch = filterAdvancedSearch;
      this.filters = { ...this.filters, ...rest };
      this.loadData(); // reload jobs
    },
    sortUpdated(sortingValues) {
      this.filters = { ...this.filters, ...sortingValues };
      this.loadData(); // reload jobs
    },
    searchQuery() {
      const searchQueryBase = {
        page_number: 0,
        ...this.filters,
        page_size: this.perPage,
        status: this.filters.status,
        excluding: this.items.map(({ api_id }) => api_id),
      };
      return this.filterUseAdvancedSearch && this.filterAdvancedSearch
        ? {
            ...searchQueryBase,
            ...{ search_json: JSON.stringify(this.filterAdvancedSearch) },
          }
        : searchQueryBase;
    },
    async refreshJob(job_sfid) {
      try {
        const { data: job } = await axios.get(`/api/brand/orders/${job_sfid}`);
        this.jobUpdated(job);
      } catch (error) {
        this.$bvToast.toast(
          error.response?.status || this.$t('_errors.network.details'),
          {
            title: this.$t('_errors.server.title'),
            variant: 'danger',
          },
        );
      }
    },
    jobUpdated(newJob) {
      this.items = this.items.map((item) => {
        return item.job.sfid === newJob.job.sfid
          ? { ...newJob, _showDetails: true }
          : item;
      });
    },
    async loadData(append = false) {
      if (!append) {
        this.totalRows = null;
        this.items = [];
      }
      if (this.totalRows !== null && this.items.length >= this.totalRows) {
        return;
      }
      this.loading = !!((this.totalRows ?? this.perPage) - this.items.length);
      if (this.loading > this.perPage) {
        this.loading = !!this.perPage;
      }
      this.loading = true;

      const data = await this.fetchBrandOrders(this.searchQuery());
      const { data: paginatedData } = data;
      this.totalRows = this.items.length + data.pagination.total_count;
      const results = paginatedData.map((i) => ({
        ...i,
        customer_number_editing: false,
        customer_number_loading: false,
      }));
      if (!this.items.length) {
        this.items = [...results];
      } else {
        this.items.push(...results);
      }
      try {
        await this.loadAveragesForBrands(paginatedData);
      } catch (e) {
        // swallow error
      } finally {
        this.loading = false;
      }
    },
    async loadBrandOpportunities() {
      const { data: opportunities } = await axios.get(
        `/api/brand/opportunities`,
      );
      if (
        opportunities &&
        opportunities.some((entry) => entry.brand_can_view_quote)
      ) {
        this.advancedFilterOptions.push({
          label: 'Invoiced by brand',
          type: 'invoiced_by_brand',
          component: 'b-form-checkbox',
        });
      }
    },
    async fetchBrandOrders(query) {
      const { data } = await Api.post(`/api/brand/orders`, query, {
        autoCancelScope: `/api/brand/orders`,
      });
      return data;
    },
    async loadOrderStatuses() {
      const { data: orderStatuses } = await axios.get(
        `/api/brand/orders/statuses`,
      );
      this.statuses = orderStatuses
        .filter(
          (orderStatus) =>
            orderStatus.job_status !== JobStatusEnum.PROPOSAL_APPROVAL_PENDING,
        )
        .map((status) => ({
          text: status.brand_name[this.locale],
          value: status.job_status,
        }));
    },
    async loadCancellationReasons() {
      const { data: cancellationReasons } = await axios.get(
        `/api/brand/cancellation-reasons`,
      );
      this.cancellations = cancellationReasons.reduce((cancellations, cr) => {
        cancellations[cr.id] = cr;
        return cancellations;
      }, {});
    },
    async loadProjectsManagers() {
      const { data: projectManagers } = await axios.get(
        `/api/brand/project-managers`,
      );
      this.projectManagers = projectManagers.map((pm) => ({
        text: `${pm.first_name} ${pm.last_name}`,
        value: pm.sfid,
      }));
    },
    async loadAveragesForBrands(orders) {
      const { data } = await axios.post('/rating-api/averages/for-brand/job', {
        ids: [...orders.map((entry) => entry.api_id)],
      });
      this.jobRatings = data;
    },
    async downloadJobsReport() {
      this.exportState = 'loading';
      try {
        await axios.post(`/api/brand/orders/export`, this.searchQuery());
        this.exportState = 'success';
      } catch (error) {
        this.exportState = 'danger';
        this.exportError = error.response.data;
      }
      console.log('show');
      this.$root.$emit('bv::show::popover', 'download-report-btn');
      setTimeout(() => {
        window.addEventListener('click', this.hidePopover);
      }, 100);
    },
    hidePopover() {
      this.$root.$emit('bv::hide::popover', 'download-report-btn');
      window.removeEventListener('click', this.hidePopover);
    },
  },
};
</script>
