<template>
  <b-card bg-variant="light" ref="stickyFilters" class="my-3">
    <b-row>
      <b-col md="3">
        <b-form-group :label="$t('jobs.filter.filter') + ':'">
          <b-input-group>
            <b-form-input
              v-e2e:filterSearch
              v-model="filterSearch"
              debounce="400"
              :placeholder="$t('jobs.filter.filter-placeholder')"
            />
            <b-input-group-append v-if="filterSearch">
              <b-btn v-e2e:filterSearchButton @click="filterSearch = ''">
                &times;
              </b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          :label="
            $t('jobs.filter.date-from') +
            ' - ' +
            $t('jobs.filter.date-to') +
            ':'
          "
        >
          <div class="d-flex">
            <date-picker v-e2e:filterDateFrom v-model="filterDateFrom" />
            <date-picker
              :end="true"
              v-e2e:filterDateTo
              v-model="filterDateTo"
              class="ml-2"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col md="3" v-if="contactProfiles.includes('admin')">
        <b-form-group :label="$t('jobs.filter.project-manager') + ':'">
          <b-form-select
            v-model="filterProjectManager"
            :options="projectManagers"
          >
            <template slot="first">
              <option :value="null">-- {{ $t('all') }} --</option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group class="mb-0">
          <template slot="label">{{ $t('jobs.file-package.label') }}:</template>
          <b-btn
            id="download-report-btn"
            v-e2e:downloadReportButton
            block
            @click="downloadReport"
            :disabled="downloadDisabled"
          >
            <b-spinner
              v-if="exportState == 'loading'"
              class="gid-spinner--button mr-2"
            ></b-spinner>
            {{ $t('jobs.file-package.generate') }}
          </b-btn>
          <b-popover
            target="download-report-btn"
            placement="bottom"
            :variant="exportState"
            triggers="manual"
          >
            <template #title>
              {{ $t(`projects.file-package.action-${exportState}`) }}
            </template>
            {{
              $t(
                exportState == 'success'
                  ? 'jobs.file-package.explain'
                  : exportError,
              )
            }}
          </b-popover>
          <small class="text-muted">
            {{ $t('jobs.file-package.count', { totalRows }) }}
          </small>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <b-form-checkbox
            class="pt-1"
            v-e2e:filterUseAdvancedSearch
            v-model="filterUseAdvancedSearch"
          >
            {{ $t('filters.advanced.toggle') }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <advanced-search
      v-if="filterUseAdvancedSearch"
      v-model="filterAdvancedSearch"
      :filter-options="advancedFilterOptions"
    />
  </b-card>
</template>

<script>
import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import AdvancedSearch from '@gid/vue-common/components/filters/AdvancedSearch.vue';

import { mapGetters } from 'vuex';
import { mapFilters } from '@gid/vue-common/store/utils';
import { SET_REPORTS_FILTER } from '@gid/vue-common/store/reports.module';

export default {
  components: {
    AdvancedSearch,
    DatePicker,
  },
  props: {
    exportState: String,
    exportError: String,
    statuses: {
      type: Array,
      default: () => [],
    },
    projectManagers: {
      type: Array,
      default: () => [],
    },
    totalRows: {
      type: Number,
      default: 0,
    },
    advancedFilterOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['contactProfiles']),
    ...mapFilters({
      moduleName: 'reports',
      states: [
        'search',
        'dateFrom',
        'dateTo',
        'projectManager',
        'useAdvancedSearch',
        'advancedSearch',
      ],
      setMutation: SET_REPORTS_FILTER,
      setCallback: 'filterUpdated',
    }),
    downloadDisabled() {
      return this.totalRows === 0 || this.exportState != 'available';
    },
  },
  methods: {
    async downloadReport() {
      this.$emit('downloadReport'); // emit to parent to download report
    },
    filterUpdated() {
      const filters = {
        search: this.filterSearch,
        project_manager: this.filterProjectManager,
        from_date: this.filterDateFrom,
        to_date: this.filterDateTo,
        filterUseAdvancedSearch: this.filterUseAdvancedSearch,
        filterAdvancedSearch: this.filterAdvancedSearch,
      };
      this.$emit('filtersUpdated', filters);
    },
  },
};
</script>
