<template>
  <div class="gid-results-count__holder d-flex">
    <b-button pill @click="onToggleFilter" class="mr-2">
      <BIconSliders />
    </b-button>
    <div class="rounded-pill gid-results-count">
      <span :class="{ 'gid-results-count--loading': isLoading }">
        <template v-if="totalCount !== null">
          {{ $t('results') }}: {{ itemsCount }} / {{ totalCount || 0 }}
        </template>
        <BIconThreeDots v-else />
      </span>
    </div>
  </div>
</template>

<script>
import { BIconSliders, BIconThreeDots } from 'bootstrap-vue';

export default {
  components: {
    BIconSliders,
    BIconThreeDots,
  },
  props: {
    isLoading: {
      type: [String, Boolean],
      default: '',
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    itemsCount: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onToggleFilter() {
      this.$emit('click');
    },
  },
};
</script>
