<template>
  <div class="car-delivery">
    <p>
      {{
        item.properties && item.properties.car_delivery
          ? $moment(item.properties.car_delivery).format('L')
          : '-'
      }}
    </p>
    <small v-if="getCarTypeExist(item)" class="text-muted d-block text-break">
      {{ $t('type') }}: {{ getCarType(item.properties.car_type) }}
    </small>
    <small
      v-if="getCarChargingTypeExist(item)"
      class="text-muted d-block text-break"
    >
      {{ $t('charging-opportunity') }}:
      {{ getChargingType(item.properties.charging_opportunity) }}
    </small>
    <p class="d-flex" v-if="shouldDisplayWallboxScore(item)">
      <DaysSinceLastChange
        v-if="item.dkv_wallbox_priority_score"
        class="ml-3"
        :days="item.dkv_wallbox_priority_score"
      />
      <span
        class="mx-2"
        v-if="getUrgentReason(item)"
        v-b-tooltip.hover
        :title="getUrgentReason(item)"
      >
        ⚠️
      </span>
    </p>
  </div>
</template>

<script>
import { JobStatusEnum } from '@gid/models';
import DaysSinceLastChange from '@gid/vue-common/components/DaysSinceLastChange';

export default {
  name: 'JobCarDeliveryInfo',
  components: {
    DaysSinceLastChange,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getCarTypeExist() {
      return (item) => item.properties?.car_type?.length;
    },
    getCarType() {
      return (car_type) => {
        let humanizedType = '';
        if (car_type === 'Voll elektrisches Auto')
          humanizedType = this.$t('car_type.electric');
        if (car_type === 'Hybrid-Auto')
          humanizedType = this.$t('car_type.hybrid');
        return humanizedType;
      };
    },
    getCarChargingTypeExist() {
      return (item) => item.properties?.charging_opportunity?.length;
    },
    getChargingType() {
      return (charging_possibility) => {
        let humanizedType = '';
        if (charging_possibility === 'Ja') {
          humanizedType = this.$t('yes');
        }
        if (charging_possibility === 'Nein') {
          humanizedType = this.$t('no');
        }
        return humanizedType;
      };
    },
    getUrgentReason() {
      return (item) => item.properties?.urgent_reason;
    },
    shouldDisplayWallboxScore() {
      return (item) => {
        const notInStatuses = [
          JobStatusEnum.CLOSED,
          JobStatusEnum.APPROVAL_PENDING,
          JobStatusEnum.FINISHED,
          JobStatusEnum.CANCELLED,
          JobStatusEnum.INVOICE_SENT,
          JobStatusEnum.COMPLETED,
        ];
        if (
          item?.productOrder &&
          item.productOrder.status !== 'all' &&
          !notInStatuses.includes(item.job.status)
        ) {
          if (!item?.is_precheck && !item?.is_activation) {
            return true;
          }
        }

        return false;
      };
    },
  },
};
</script>
