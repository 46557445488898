<template>
  <div class="order-details">
    <template v-if="item.job.eid">
      <a
        :href="`#edit-eid-${item.job.sfid}`"
        v-if="canEditOrderEID"
        @click.prevent="
          editEID(item.job.sfid, item.job.eid, InputTypes.ORDER_EID)
        "
      >
        {{ item.job.eid }}
      </a>
      <span v-else>{{ item.job.eid }}</span>
    </template>
    <span v-else>{{ item.job.name }}</span>
    <br />
    <span
      v-if="!item.customer.eid && !item.customer_number_editing"
      @click="editCustomerEid(item)"
      class="text-primary"
      role="button"
    >
      {{ $t('customer-number-add') }}
    </span>
    <span v-if="item.customer.eid && !item.customer_number_editing">
      / {{ item.customer.eid }}
      <i
        class="material-icons gid-brand-eid-edit"
        role="button"
        @click="editCustomerEid(item)"
      >
        edit
      </i>
    </span>
    <template v-if="item.customer_number_editing">
      <b-form-input
        v-model="customerEid"
        :disabled="item.customer_number_loading"
        :placeholder="$t('inputs.customer.eid')"
      ></b-form-input>
      <div class="my-2">
        <b-button
          @click="setCustomerEid(item.job.api_id, item)"
          :disabled="item.customer_number_loading"
          class="p-1"
        >
          <b-spinner
            v-if="item.customer_number_loading"
            class="gid-spinner--button mr-2"
          ></b-spinner>
          <i class="material-icons">done</i>
        </b-button>

        <b-button
          @click="cancelEidEditing(item)"
          :disabled="item.customer_number_loading"
          class="p-1 mx-2"
        >
          <i class="material-icons">close</i>
        </b-button>
      </div>
    </template>
    <a
      v-if="item.job.invoicing_eid"
      :href="`#edit-invoice-eid-${item.job.sfid}`"
      class="text-muted d-block text-break"
      @click.prevent="
        editEID(item.job.sfid, item.job.invoicing_eid, InputTypes.INVOICING_EID)
      "
    >
      {{ item.job.invoicing_eid }}
    </a>
    <RatingWidget context="job" v-bind="jobRatings[item.job.api_id]" />
    <div>
      <a
        href="#"
        v-if="shouldDisplayQuote(item)"
        @click.prevent="getQuotePath(item.job.sfid)"
      >
        {{ $t('view-quote') }}
      </a>
      <a
        href="#"
        v-if="shouldDisplayBrandQuote(item)"
        @click.prevent="getBrandQuotePath(item.job.sfid)"
      >
        {{ $t('view-brand-quote') }}
      </a>
    </div>
    <b-badge v-if="hasOwnInstaller(item)" variant="dark">
      {{ $t(`job.has-own-installer`) }}
    </b-badge>
    <b-modal ref="orderEidModal" :title="`Enter new eid`" @ok="saveOrderEID">
      <b-form-group :label="eidModalLabel">
        <b-form-input v-model="edit_eid_modal.eid" />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as InputTypes from '@gid/vue-common/components/inputs/_types';
import { JobStatusEnum, AccountProfileEnum } from '@gid/models';
import RatingWidget from '@gid/vue-common/components/RatingWidget.vue';
import axios from 'axios';

export default {
  components: {
    RatingWidget,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    jobRatings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      edit_eid_modal: {
        field: null,
        job_id: null,
        eid: null,
      },
      customerEid: '',
      eidModalLabel: '',
    };
  },
  computed: {
    ...mapGetters(['user', 'access_token']),
    canEditOrderEID() {
      return this.user?.account?.profiles.includes(
        AccountProfileEnum.EDIT_ORDER_EID,
      );
    },
    hasOwnInstaller() {
      return (job) => {
        return job?.job?.own_installer;
      };
    },
    shouldDisplayBrandQuote() {
      return (item) => {
        const statusesArray = [
          JobStatusEnum.COMPLETED,
          JobStatusEnum.INVOICE_SENT,
          JobStatusEnum.OPEN,
          JobStatusEnum.CANCELLED,
          JobStatusEnum.ON_HOLD,
          JobStatusEnum.ACCEPTED,
          JobStatusEnum.FINISHED,
          JobStatusEnum.APPROVAL_PENDING,
          JobStatusEnum.CLOSED,
          JobStatusEnum.APPOINTMENT_CONFIRMED,
          JobStatusEnum.STARTED,
        ];
        return (
          item.job.status === JobStatusEnum.BRAND_PROPOSAL_APPROVAL_PENDING ||
          (statusesArray.includes(item.job.status) &&
            item.opportunity.brand_can_view_brand_quote)
        );
      };
    },
    shouldDisplayQuote() {
      return (item) => {
        const statusesArray = [
          JobStatusEnum.PROPOSAL_APPROVAL_PENDING,
          JobStatusEnum.ACCEPTED,
          JobStatusEnum.ON_HOLD,
          JobStatusEnum.APPOINTMENT_CONFIRMED,
          JobStatusEnum.APPROVAL_PENDING,
          JobStatusEnum.FINISHED,
          JobStatusEnum.OPEN,
          JobStatusEnum.INVOICE_SENT,
          JobStatusEnum.COMPLETED,
        ];
        return !!(
          statusesArray.includes(item.job.status) &&
          item.opportunity.brand_can_view_quote
        );
      };
    },
  },
  methods: {
    cancelEidEditing(item) {
      item.customer_number_editing = false;
    },
    editCustomerEid(item) {
      item.customer_number_editing = true;
      this.customerEid = item.customer.eid;
    },
    editEID(job_id, eid, field) {
      this.edit_eid_modal.job_id = job_id;
      this.edit_eid_modal.eid = eid;
      this.edit_eid_modal.field = field;
      switch (field) {
        case InputTypes.ORDER_EID:
          this.eidModalLabel = 'Order EID';
          break;
        case InputTypes.INVOICING_EID:
          this.eidModalLabel = 'Invoice EID';
          break;
      }
      this.$refs.orderEidModal.show();
    },
    async getQuotePath(job_id) {
      const { data } = await axios.get(`/api/brand/orders/${job_id}/quote`);
      const path = `/api/media/file/${data}?jwt=${this.access_token}`;
      window.open(path, '_blank').focus();
    },
    async getBrandQuotePath(job_id) {
      const { data } = await axios.get(
        `/api/brand/orders/${job_id}/brand-quote`,
      );
      const path = `/api/media/file/${data}?jwt=${this.access_token}`;
      window.open(path, '_blank').focus();
    },
    async saveOrderEID() {
      try {
        await axios.post(`/api/brand/orders/set-status`, {
          preview: false,
          new_status: null,
          job_id: this.edit_eid_modal.job_id,
          inputs: {
            [this.edit_eid_modal.field]: this.edit_eid_modal.eid,
          },
        });
        this.$emit('refreshJob', this.edit_eid_modal.job_id);
      } catch (e) {
        console.log(e);
      }
    },
    async setCustomerEid(job_api_id, item) {
      try {
        item.customer_number_loading = true;
        const { data } = await axios.put(
          `/api/brand/orders/${job_api_id}/customer-eid`,
          {
            job_api_id,
            customer_eid: this.customerEid,
            customer_id: item.customer.id,
          },
        );
        item.customer_number_loading = false;
        item.customer_number_editing = false;
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>
<style scoped>
.gid-brand-eid-edit {
  font-size: 1em;
}
</style>
