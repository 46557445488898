<template>
  <div class="table-actions">
    <b-button size="sm" class="mr-1" @click.stop="toggleDetails">
      {{ $t('jobs.details') }}
    </b-button>
    <b-button variant="light" size="sm" @click.stop="showLogs">Logs</b-button>
    <div class="my-3" v-if="shouldDisplayBrandActions(item)">
      <p>
        <strong>{{ $t('quote.actions') }}</strong>
        :
      </p>
      <b-button
        variant="primary"
        size="sm"
        class="mr-1"
        @click="acceptQuote(item)"
        :disabled="quoteSaving"
      >
        <b-spinner small v-if="quoteSaving"></b-spinner>
        {{ $t('quote.accept') }}
      </b-button>
      <b-button
        variant="danger"
        size="sm"
        @click="rejectQuote(item)"
        :disabled="quoteSaving"
      >
        <b-spinner small v-if="quoteSaving"></b-spinner>
        {{ $t('quote.reject') }}
      </b-button>
    </div>
    <b-dropdown
      v-if="jobHasDocs(item)"
      right
      size="sm"
      text="Docs"
      class="mr-1"
    >
      <b-dropdown-item
        v-if="item.files.picture_before"
        :href="`/api/media/file/${item.files.picture_before.paths[0]}?jwt=${access_token}`"
        target="_blank"
      >
        {{ $t('jobs.image.start') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="item.files.picture_after"
        :href="`/api/media/file/${item.files.picture_after.paths[0]}?jwt=${access_token}`"
        target="_blank"
      >
        {{ $t('jobs.image.finish') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="item.files.protocol"
        :href="`/api/media/file/${item.files.protocol.paths[0]}?jwt=${access_token}`"
        target="_blank"
      >
        {{ $t('jobs.image.protocol') }}
      </b-dropdown-item>
      <template v-if="item.files.picture">
        <b-dropdown-item
          v-for="(path, index) in item.files.picture.paths"
          :key="`picture-${index}`"
          :href="`/api/media/file/${path}?jwt=${access_token}`"
          target="_blank"
        >
          {{ $t('jobs.image.picture', { index }) }}
        </b-dropdown-item>
      </template>
      <template v-if="item.files.planning_customer">
        <b-dropdown-item
          v-for="(path, index) in item.files.planning_customer.paths"
          :key="`customer-${index}`"
          :href="`/api/media/file/${path}?jwt=${access_token}`"
          target="_blank"
        >
          {{ $t('jobs.image.planning_customer', { index }) }}
        </b-dropdown-item>
      </template>
      <template v-if="item.files.planning_partner">
        <b-dropdown-item
          v-for="(path, index) in item.files.planning_partner.paths"
          :key="`partner-${index}`"
          :href="`/api/media/file/${path}?jwt=${access_token}`"
          target="_blank"
        >
          {{ $t('jobs.image.planning_partner', { index }) }}
        </b-dropdown-item>
      </template>
    </b-dropdown>
    <b-checkbox
      v-if="shouldBeAbleToInvoice(item)"
      :disabled="item.job.invoiced_by_brand__c"
      :checked="item.job.invoiced_by_brand__c"
      @change="invoiceOrder"
    >
      {{ $t('invoice') }}
    </b-checkbox>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { JobStatusEnum } from '@gid/models';
import axios from 'axios';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      quoteSaving: false,
    };
  },
  computed: {
    ...mapGetters(['access_token']),
    shouldDisplayBrandActions() {
      return (item) => {
        return (
          item.job.status === JobStatusEnum.BRAND_PROPOSAL_APPROVAL_PENDING
        );
      };
    },
    shouldBeAbleToInvoice() {
      return (item) => {
        // https://app.asana.com/0/772623105799740/1201017931287722
        return ['0060800000uCOScAAO'].includes(item.opportunity.sfid);
      };
    },
  },
  methods: {
    toggleDetails() {
      this.$emit('toggle');
    },
    showLogs() {
      this.$emit('showLogs');
    },
    async acceptQuote(item) {
      try {
        const job_id = item.job.sfid;
        this.quoteSaving = true;
        const payload = {
          preview: false,
          new_status: item.partner?.id
            ? JobStatusEnum.ACCEPTED
            : JobStatusEnum.OPEN,
          job_id: item.job.sfid,
        };
        await axios.post(`/api/brand/orders/set-status`, payload);
        this.quoteSaving = false;
        this.$emit('refreshJob', job_id);
      } catch (e) {
        this.quoteSaving = false;
        throw new Error(e.message);
      }
    },
    async rejectQuote(item) {
      const job_id = item.job.sfid;
      try {
        this.quoteSaving = true;
        await axios.post(`/api/brand/orders/set-status`, {
          preview: false,
          new_status: JobStatusEnum.CANCELLED,
          job_id,
        });
        this.quoteSaving = false;
        this.$emit('refreshJob', job_id);
      } catch (e) {
        this.quoteSaving = false;
        throw new Error(e.message);
      }
    },
    async invoiceOrder() {
      const job_id = this.item.job.sfid;
      await axios.put(`/api/brand/orders/${job_id}/invoice`);
      this.$emit('refreshJob', job_id);
    },
    jobHasDocs(job_view) {
      return Object.keys(job_view.files).length > 0;
    },
  },
};
</script>
