<template>
  <div class="project-manager">
    <span v-if="showProjectOwners">
      <span v-for="pm in item.project_owners" :key="pm">
        {{ `${pm.first_name} ${pm.last_name}` }}
      </span>
    </span>
    <span v-if="hideProjectOwners">None</span>
    <project-manager-dropdown
      :value="selectedProjectManager(item) || ''"
      :projectId="item.project.id"
      :setProjectManager="setProjectManager"
      :projectManagers="projectManagers"
      v-if="contactProfiles.includes('admin')"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import ProjectManagerDropdown from '@/components/ProjectManagerDropdown.vue';

export default {
  name: 'JobProjectManager',
  components: {
    ProjectManagerDropdown,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      projectManagers: [],
    };
  },
  computed: {
    ...mapGetters(['contactProfiles']),
    showProjectOwners() {
      return (
        this.item.project_owners.length &&
        this.contactProfiles.includes('admin')
      );
    },
    hideProjectOwners() {
      return (
        !this.item.project_owners.length &&
        !this.contactProfiles.includes('admin')
      );
    },
    selectedProjectManager() {
      return (item) => {
        if (item && item.project_owners.length) {
          return item.project_owners.map((entry) => ({
            value: entry.sfid,
            text: `${entry.first_name} ${entry.last_name}`,
          }))[0];
        }
        return { value: '', text: '' };
      };
    },
  },
  methods: {
    async setProjectManager(projectId, pmId) {
      try {
        await axios.put(`/api/brand/projects/set-project-owner`, {
          project_id: `${projectId}`,
          contact_sfid: pmId,
        });
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
</script>
